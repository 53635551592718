import React, { useCallback, useEffect, useRef, useState } from 'react'
import Webcam from "react-webcam";
import { Buffer } from "buffer";
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { Link, useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import OtpInput from 'react-otp-input';
import DateTimePicker from 'react-datetime-picker'
import cookies from "js-cookie";

// Components
import Loader from './Loader/Loader';
import Custom_dropdown from './Custom_dropdown';

// Images
import Logo from '../assets/img/Website Assets/1-ART Logo/Logo - Point Rouge.png'
import Logo1 from '../assets/img/Website Assets/GTO Carousel/GTO - Bureau 2.png'
import UserAvator from '../assets/img/Website Assets/Icons/user.png'
import FlecheIcon from '../assets/img/Website Assets/Icons/Fleche.png'
import UtilisateurIcon from '../assets/img/Website Assets/Icons/utilisateur.png'
import FranceIcon from '../assets/img/Website Assets/Icons/France.png'
import APIService from '../networks/APIService';
import marinoImage from '../assets/img/Website Assets/GTO Carousel/GTO - Bureau 2.png'
import BuyIcon from '../assets/img/Website Assets/Icons/Buy.png'
import VideoIcon from '../assets/img/videoIcon.png'

const videoConstraints = {
  width: 640,
  height: 480,
  facingMode: "user",
};

const Header = () => {
  const nfcNumber = window?.location?.pathname?.split('/')?.[3]
  const { t } = useTranslation();
  const temp = localStorage.getItem('user');
  const auth = JSON.parse(temp);
  const tempUserSerialNumber = localStorage.getItem('userSerialNumber');
  const userSerialNumber = JSON.parse(tempUserSerialNumber);

  const isProductOwner = userSerialNumber && userSerialNumber.includes(nfcNumber)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [productImages, setProductImages] = useState([])
  const [message, setMessage] = useState('')
  const [metaInformation, setMetaInformation] = useState([]);
  const [uploadedImagesUrl, setUploadedImagesUrl] = useState([]);
  const [showLiveVideo, setShowLiveVideo] = useState(false)
  const [showLiveVideoModal, setShowLiveVideoModal] = useState(false)
  const [fileNames, setFileNames] = useState({
    regular: 'Photos/Vidéos',
    live: 'Live Photos/Vidéos',
    regular1: 'Photos/Vidéos',
    live1: 'Live Photos/Vidéos',
  });
  const [reload, setReload] = useState(false)
  const [transactionMessage, setTransactionMessage] = useState('')
  const [transactionFirstName, setTransactionFirstName] = useState('')
  const [transactionLastName, setTransactionLastName] = useState('')
  const [transactionEmail, setTransactionEmail] = useState('')
  const [transactionConfirmEmail, setTransactionConfirmEmail] = useState('')
  const [otpSuccess, setOtpSuccess] = useState(false)
  const [opt1, setOtp1] = useState('')
  const [isDeletePhotoOpen, setIsDeletePhotoOpen] = useState(false)
  const [selectedDeletedImgUrl, setSelectedDeletedImgUrl] = useState('')
  const [selectedDeletedImgUrlForAPI, setSelectedDeletedImgUrlForAPI] = useState('')
  const [giftFirstName, setGiftFirstName] = useState('')
  const [giftLastName, setGiftLastName] = useState('')
  const [giftEmail, setGiftEmail] = useState('')
  const [giftConfirmEmail, setGiftConfirmEmail] = useState('')
  const [giftMessage, setGiftMessage] = useState('')
  const [giftDiff, setGiftDiff] = useState(false)
  const [dateTime, setDateTime] = useState()

  const webcamRef = useRef(null);
  const webcamRef1 = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [LiveVideoIsVisible, setLiveVideoIsVisible] = useState(false)


  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef1.current.stream, {
      mimeType: 'video/mp4'
    });
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef1, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(({ data }) => {
    if (data.size > 0) {
      setRecordedChunks(prev => prev.concat(data));
    }
  }, [setRecordedChunks]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, setCapturing]);

  const handleUploadVideo = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: 'video/mp4'
      });
      const formData = new FormData();
      formData.append('video', blob);

      APIService.uploadVideo(formData).then((response) => {
        toast('Video uploaded successfully.')
        setProductImages([response.upload.secure_url, ...productImages]);
        setUploadedImagesUrl([response.upload.secure_url, ...uploadedImagesUrl]);
        setReload(!reload)
        setRecordedChunks([])
        setLiveVideoIsVisible(false)
        setLoading(false)
      }).catch((error) => {
        setLoading(false)
        console.log('error', error)
      });
    }
  }, [recordedChunks]);

  const captureModal = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();

    const base64Data = imageSrc.replace(/^data:image\/\w+;base64,/, "");


    setLoading(true)
    APIService.uploadBase64Image(imageSrc).then((response) => {
      setProductImages([response.upload.secure_url, ...productImages]);
      setUploadedImagesUrl([response.upload.secure_url, ...uploadedImagesUrl]);
      setReload(!reload)
      setLoading(false)
    }).catch((error) => {
      setUploadedImagesUrl([])
      setLoading(false)
      console.log('error', error)
    });
    setShowLiveVideoModal(false)
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  console.log('uploadedImagesUrl11111111>>>>>>', productImages)
  // const capture = useCallback(() => {
  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();

    const base64Data = imageSrc.replace(/^data:image\/\w+;base64,/, "");
    const binaryData = Buffer.from(base64Data, 'base64');


    setLoading(true)
    APIService.uploadBase64Image(imageSrc).then((response) => {
      setProductImages([response.upload.secure_url, ...productImages]);
      setUploadedImagesUrl([response.upload.secure_url, ...uploadedImagesUrl]);
      setReload(!reload)
      console.log(';1')
      // getProduct()
      setLoading(false)
    }).catch((error) => {
      setUploadedImagesUrl([])
      setLoading(false)
      console.log('error', error)
    });
    setShowLiveVideo(false)
    setImgSrc(imageSrc);
  }

  useEffect(() => {
    if (nfcNumber != undefined) {
      getProduct()
    }
  }, [window.location.pathname])

  const getProduct = async () => {
    setLoading(true);
    APIService.getSingleProduct(nfcNumber).then((response) => {
      const grouped = response.metaDetail.map((item) => {
        return {
          [item.key]: item.value
        }
      })
      setMetaInformation(grouped)
      setProduct(response?.product?.[0] ?? null)
      setProductImages(response?.product?.[0]?.productImages ?? [])
      setMessage(response?.product?.[0]?.message)
      setLoading(false);
    }
    ).catch((error) => {
      setProduct(null)
      setLoading(false);
    });
  }

  const handleFileChange = (event, type) => {
    setLoading(true)
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileNames(prevState => ({
        ...prevState,
        [type]: selectedFile.name
      }));
    } else {
      setFileNames(prevState => ({
        ...prevState,
        [type]: type === 'regular' ? 'Photos/Vidéos' : 'Live Photos/Vidéos'
      }));
    }
    APIService.uploadImage(event.target.files).then((response) => {
      toast(t('Image uploaded successfully.'))
      const temp = []
      response.upload.forEach(_ => {
        temp.push(_.secure_url)
      })

      setUploadedImagesUrl([...uploadedImagesUrl, ...temp])
      setProductImages([...temp, ...productImages])
      getProduct()
      setLoading(false)
    }).catch((error) => {
      setUploadedImagesUrl([])
      setLoading(false)
      console.log('error', error)
    });
  };

  const onDrop = React.useCallback((files) => {
    const maxSizeInMB = 100;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    if (files && files.length) {
      setLoading(true)
      const filename = files[0].name;

      var parts = filename.split(".");

      if (parts?.[1] === 'mp4' || parts?.[1] === 'webp' || parts?.[1] === 'tipp' || parts?.[1] === 'avi' || parts?.[1] === 'wmv' || parts?.[1] === 'webm' || parts?.[1] === 'flv' || parts?.[1] === 'm4v' || parts?.[1] === 'mov' || parts?.[1] === 'm-jepg') {
        if (+files[0].size > +maxSizeInBytes) {
          setLoading(false)
          toast('File size should be less than 100MB')
        } else {
          const formData = new FormData();
          formData.append('video', files[0]);
          APIService.uploadVideoFile(formData).then((response) => {
            toast('Video uploaded successfully.')
            setProductImages([response.upload.secure_url, ...productImages]);
            setUploadedImagesUrl([response.upload.secure_url, ...uploadedImagesUrl]);
            setReload(!reload)
            setRecordedChunks([])
            setLiveVideoIsVisible(false)
            setLoading(false)
          }).catch((error) => {
            setLoading(false)
            console.log('error', error)
          });
        }
      } else {

        APIService.uploadImage(files).then((response) => {
          const temp = []
          response.upload.forEach(_ => {
            temp.push(_.secure_url)
          })
          setUploadedImagesUrl([...temp, ...uploadedImagesUrl])
          setProductImages([...temp, ...productImages])
          setLoading(false)
          setUploadedImagesUrl(temp)
        }).catch((error) => {
          setLoading(false)
          setUploadedImagesUrl([])
          console.log('error', error)
        });
      }
    }
  }, [productImages]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate('/login')
  }

  const saveDragedImages = (images) => {
    setLoading(true)

    APIService.saveDragImages(product.id, message, images,).then((response) => {
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      console.log('error', error)
    });
  }

  const updateProductInformation = () => {
    setLoading(true)
    APIService.customizeProduct(product.id, message, uploadedImagesUrl, nfcNumber).then((response) => {
      setLoading(false)
      window.location.reload()
    }).catch((error) => {
      setLoading(false)
      console.log('error', error)
    });
  }

  // Component to render AST
  // const RenderNode = ({ node }) => {
  //   if (!node) return null;

  //   switch (node.type) {
  //     case 'root':
  //       return <div>{node.children.map((child, index) => <RenderNode key={index} node={child} />)}</div>;

  //     case 'paragraph':
  //       return <p>{node.children.map((child, index) => <RenderNode key={index} node={child} />)}</p>;

  //     case 'text':
  //       return <>{node.value}</>;

  //     default:
  //       return null;
  //   }
  // };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list.images);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    display: 'flex',
    padding: grid,
    overflow: 'auto',
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      { images: productImages },
      result.source.index,
      result.destination.index
    );
    setProductImages(items)
    saveDragedImages(items)
  }

  const sendOtp = () => {
    setOtpSuccess(false)
    if (!transactionFirstName || !transactionLastName || !transactionEmail || !transactionMessage) {
      toast(t('Please fill all fields'))
    } else if (
      transactionEmail !== transactionConfirmEmail
    ) {
      toast(t('Email and Confirm Email should be same'))
    } else {
      setLoading(true)
      APIService.transactionOtp(transactionMessage, transactionFirstName, transactionLastName, transactionEmail, auth.email).then((response) => {
        toast(t('Otp sent successfully'))
        setOtpSuccess(true)
        setLoading(false)
        setReload(!reload)
      }).catch((error) => {
        setOtpSuccess(false)
        setLoading(false)
        console.log('error', error)
      });
    }
  }

  const verifyTransactionOtp = (isGift) => {
    if (isGift) {
      setLoading(true)
      APIService.verifyGiftOtp(giftEmail, opt1, auth.email, nfcNumber, giftMessage, giftFirstName, giftLastName, uploadedImagesUrl, product.id, giftDiff, dateTime).then((response) => {
        toast(t('Transaction is successful'))
        navigate('/collection')
        setOtp1('')
        setLoading(false)
      }).catch((error) => {
        toast(t('Transaction is not successful, Invalid OTP'))
        setLoading(false)
        console.log('error', error)
      });
    } else {
      setLoading(true)
      APIService.verifyTransactionOtp(transactionEmail, opt1, auth.email, nfcNumber, transactionMessage, transactionFirstName, transactionLastName, product.id).then((response) => {
        toast(t('Transaction is successful'))
        navigate('/collection')
        setOtp1('')
        setLoading(false)
      }).catch((error) => {
        toast(t('Transaction is not successful, Invalid OTP'))
        setLoading(false)
        console.log('error', error)
      });
    }
  }

  const largePhoto = (url) => {
    // setIsDeletePhotoOpen(true)
    const tempImageArr = productImages.filter((item) => item !== url)
    setProductImages(tempImageArr)
    setSelectedDeletedImgUrl(url)
  }
  const handleDeletePhoto = () => {
    setLoading(true)
    APIService.deleteImage(product.id, selectedDeletedImgUrlForAPI).then((response) => {
      setLoading(false)
      window.location.reload()
      setIsDeletePhotoOpen(false)
      selectedDeletedImgUrl('')
      setSelectedDeletedImgUrlForAPI('')
    }).catch((error) => {
      setLoading(false)
      console.log('error', error)
    });
  }

  const validateGift = () => {
    if (!giftFirstName || !giftLastName || !giftEmail || !giftConfirmEmail || !giftMessage) {
      toast(t('Please fill all fields'))
    } else if (
      giftEmail !== giftConfirmEmail
    ) {
      toast(t('Email and Confirm Email should be same'))
    } else {
      setLoading(true)
      APIService.sendGift(giftFirstName, giftLastName, giftEmail, giftMessage, giftDiff, uploadedImagesUrl, auth.email).then((response) => {
        toast(t('Otp Send Successfully'))
        setLoading(false)
        setReload(!reload)
      }).catch((error) => {
        setLoading(false)
        console.log('error', error)
      });
    }
  }

  const toggleCheckbox = () => {
    setGiftDiff(!giftDiff)
  }

  const onChangeDateTime = (date) => {
    setDateTime(new Date(date.target.value).toISOString())
  }

  const changeLang = (lang) => {
    cookies.set("i18next", lang);
    window.location.reload();
  };
  const fileExtension = product?.productImages?.[0] && product?.productImages[0].substring(product?.productImages[0].lastIndexOf('.') + 1);

  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollPos > currentScrollPos);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  return (
    <header id="navbar"
      style={{
        position: "fixed",
        top: visible ? "0" : "-60px",
        transition: "top 0.8s",
        backgroundColor: "white",
      }}>
      {reload}
      <div class="d-flex justify-content-between align-items-center m-3 ms-4">
        <div class="logo w-25">
          <a href="https://1-art.co/">
            <img
              src={Logo}
              class="logo-img"
            />
          </a>
        </div>
        {console.log('isProductOwnerisProductOwner', auth?.id && isProductOwner || window.location.pathname === '/collection')}
        {auth?.id && isProductOwner === true || window.location.pathname === '/collection' ?
          <div class="menu-personal w-100">
            <ul
              class="d-flex justify-content-around align-items-center list-unstyled m-0"
            >
              {window.location.pathname.split('/')[1] != 'collection' &&
                <li class="ms-5 cursor_pointer" >
                  <Link to={auth?.id ? "/collection" : "/login"} style={{ textDecoration: "none", color: 'black' }}>
                    <h6
                      class="m-0 fw-bold cursor_pointer"
                    >
                      {t('myCollection')}
                    </h6>
                  </Link>
                </li>
              }
              {window.location.pathname.split('/')[1] === 'home' &&
                <li class="ms-5 cursor_pointer">
                  <h6
                    class="m-0 fw-bold cursor_pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalDesktop"
                  >
                    {t("Personalize")}
                  </h6>
                  <div
                    class="modal fade"
                    id="exampleModalDesktop"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered w_90">
                      <div class="modal-content bg-transparent border-0">
                        <div class="modal-body">
                          <div class="row">
                            <div class="col-md-8">
                              <div
                                class="personal-contact-img position-relative bg-white p-3 max-h-100 h-100"
                              >
                                <div
                                  class="personal-content d-flex align-items-center mb-3"
                                >
                                  <img
                                    src={UserAvator}
                                    alt=""
                                    class="me-2"
                                  />
                                  <div class="author-name">
                                    <h6 class="m-0">{auth?.firstName + ' ' + auth?.lastName ?? 'Matthew Tippetts'}</h6>
                                  </div>
                                </div>
                                <div className='w-100 text-center bg-lightgrey'>
                                  {fileExtension === 'mp4' ?
                                    <video autoPlay={true} muted controls className='w-100'>
                                      <source src={product?.productImages[0]} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                    :
                                    <img
                                      src={selectedDeletedImgUrl != "" ? selectedDeletedImgUrl : product?.productImages[0]}
                                      class="contact-img-border"
                                    />
                                  }
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="contact-border-personal bg-white py-5">
                                <button
                                  type="button"
                                  className="btn-close"
                                  aria-label="Close"
                                  data-bs-dismiss="modal"
                                  style={{ position: 'absolute', right: 30, top: 30 }}
                                ></button>
                                <h3 class="text-center mb-4">{t('Personalize')}</h3>

                                <p class="text-center">
                                  {t('drag')}
                                </p>
                                {isDeletePhotoOpen ?
                                  <React.Fragment>
                                    <div className='position-relative'>
                                      <div style={{ position: 'absolute', left: -10, top: -8, width: 25, height: 25, textAlign: 'center', backgroundColor: '#fff', marginRight: 0, borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', }} onClick={() => {
                                        setIsDeletePhotoOpen(false)
                                        setSelectedDeletedImgUrl('')
                                        setSelectedDeletedImgUrlForAPI('')
                                      }}>
                                        <i class="fa-solid fa-xmark cursor_pointer" style={{ fontSize: 15, }} aria-label="Close"></i>
                                      </div>
                                      <img
                                        src={selectedDeletedImgUrl}
                                        class="w-100 mb-3"
                                      />
                                    </div>
                                    <div className='row'>
                                      <div className='col-lg-6 col-md-12 mb-4'>
                                        <label
                                          class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                          htmlFor='regularFileInput'
                                        >{fileNames.regular}</label>
                                        <input
                                          {...getInputProps()}
                                          {...getRootProps()}
                                          type="file"
                                          className="custom-file-input"
                                          id="regularFileInput"
                                          accept="image/*"
                                          capture={false}
                                          onChange={(event) => handleFileChange(event, "regular")}
                                        />
                                      </div>
                                      <div className='col-lg-6 col-md-12 mb-4' onClick={() => setShowLiveVideo(true)}>
                                        <label
                                          class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                          htmlFor='liveFileInput'>
                                          {fileNames.live}
                                        </label>
                                      </div>
                                    </div>


                                    <div
                                      onClick={() => handleDeletePhoto()}
                                      class="btn btn-register d-flex justify-content-center align-items-center w-100">
                                      {loading ? <Loader /> :
                                        <React.Fragment>
                                          <p class="lh-sm text-start m-0">{t('confirm')}</p>
                                          <img
                                            src={FlecheIcon}
                                            width="60"
                                          />
                                        </React.Fragment>
                                      }
                                    </div>
                                  </React.Fragment>
                                  :
                                  <React.Fragment>
                                    <DragDropContext onDragEnd={onDragEnd}>
                                      <Droppable droppableId="droppable" direction="horizontal">
                                        {(provided, snapshot) => (
                                          <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                          >
                                            {productImages && productImages.length > 0 && productImages.map((item, index) => {
                                              const fileExtension = item.substring(item.lastIndexOf('.') + 1);
                                              return (
                                                <Draggable key={JSON.stringify(item)} draggableId={JSON.stringify(item)} index={index}>
                                                  {(provided, snapshot) => (
                                                    <div
                                                      className='position-relative  product-close-main'
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                      )}
                                                    >
                                                      <div className='product-close' style={{ position: 'absolute', right: 20, top: 12, width: 25, height: 25, textAlign: 'center', backgroundColor: '#fff', marginRight: 0, borderRadius: 10, justifyContent: 'center', alignItems: 'center', }}
                                                        onClick={() => {
                                                          largePhoto(fileExtension === 'mp4' || fileExtension === 'mov' || fileExtension === 'webm' || fileExtension === 'avi' ? VideoIcon : item)
                                                          setSelectedDeletedImgUrlForAPI(item)
                                                        }}>
                                                        <i class="fa-solid fa-xmark cursor_pointer" style={{ fontSize: 15, }} aria-label="Close"></i>
                                                      </div>

                                                      <img
                                                        src={fileExtension === 'mp4' || fileExtension === 'mov' || fileExtension === 'webm' || fileExtension === 'avi' ? VideoIcon : item}
                                                        class="personal-add-item-img me-md-2 me-4 mb-3"
                                                      />
                                                      {/* } */}
                                                    </div>
                                                  )}
                                                </Draggable>
                                              )
                                            })}
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </DragDropContext>
                                    <div className='row mt-5'>
                                      <div className='col-12'>
                                        <div class="position-relative mb-4">
                                          <label
                                            for="formGroupExampleInput"
                                            class="form-label bg-white start_15 position-absolute px-3"
                                          >{t('Message')}</label>
                                          <textarea
                                            type="text"
                                            rows="3"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            class="form-control w_400_area w-100"
                                            id="formGroupExampleInput" />
                                        </div>
                                      </div>
                                      <div className='col-lg-6 col-md-12 mb-4'>
                                        <label
                                          class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                          htmlFor='regularFileInput'
                                        >{fileNames.regular}</label>
                                        <input
                                          {...getInputProps()}
                                          {...getRootProps()}
                                          type="file"
                                          className="custom-file-input"
                                          id="regularFileInput"
                                          accept="image/*"
                                          capture={false}
                                          onChange={(event) => handleFileChange(event, "regular")}
                                        />
                                      </div>
                                      <div className='col-lg-6 col-md-12 mb-4' onClick={() => setShowLiveVideo(true)}>
                                        <label
                                          class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                          style={{ textAlign: 'center' }}
                                          htmlFor='liveFileInput'>
                                          {fileNames.live}
                                        </label>
                                        {/* <input
                                      {...getInputProps()}
                                    {...getRootProps()}
                                      type="file"
                                      className="custom-file-input"
                                      id="liveFileInput"
                                      accept="image/*"
                                      onChange={(event) => handleFileChange(event, "live")}
                                    /> */}
                                      </div>
                                    </div>
                                    {LiveVideoIsVisible &&
                                      <React.Fragment>
                                        <Webcam style={{ width: "100%" }} audio={true} ref={webcamRef1} />
                                        {capturing ? (
                                          <div
                                            onClick={handleStopCaptureClick}
                                            style={{ marginTop: 10, marginBottom: 10 }}
                                            class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                            Stop Capture
                                          </div>
                                        ) : (
                                          <div
                                            onClick={handleStartCaptureClick}
                                            style={{ marginTop: 10, marginBottom: 10 }}
                                            class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                            Start Capture
                                          </div>
                                        )}
                                        {recordedChunks.length > 0 && (
                                          <div
                                            onClick={handleUploadVideo}
                                            style={{ marginTop: 10, marginBottom: 10 }}
                                            class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                            Upload Video
                                          </div>
                                        )}
                                      </React.Fragment>
                                    }
                                    {showLiveVideo &&
                                      <React.Fragment>
                                        <Webcam
                                          audio={false}
                                          ref={webcamRef}
                                          screenshotFormat="image/png"
                                          style={{ width: "100%" }}
                                          videoConstraints={videoConstraints}
                                        />
                                        <div
                                          onClick={() => capture()}
                                          style={{ marginTop: 10, marginBottom: 10 }}
                                          class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                          {t('Capture photo')}
                                        </div>
                                        <div
                                          onClick={() => {
                                            setLiveVideoIsVisible(true)
                                            setShowLiveVideo(false)
                                          }}
                                          style={{ marginTop: 10, marginBottom: 10 }}
                                          class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">
                                          Live Video
                                        </div>
                                      </React.Fragment>
                                    }
                                    <div
                                      onClick={() =>{
                                        if(selectedDeletedImgUrlForAPI === "" || selectedDeletedImgUrlForAPI  === null) {
                                          updateProductInformation()
                                          } else {
                                            handleDeletePhoto()
                                          }
                                       }}
                                      class="btn btn-register d-flex justify-content-center align-items-center w-100">
                                      {loading ? <Loader /> :
                                        <React.Fragment>
                                          <p class="lh-sm text-start m-0">{t('confirm')}</p>
                                          <img
                                            src={FlecheIcon}
                                            width="60"
                                          />
                                        </React.Fragment>
                                      }
                                    </div>
                                  </React.Fragment>
                                }
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="modal fade"
                    id="imageShower"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered w_90">
                      <div class="modal-content bg-transparent border-0">
                        <div class="modal-body">
                          <div class="row">
                            <div class="col-md-8">
                              <div
                                class="personal-contact-img position-relative bg-white p-3 max-h-100 h-100"
                              >
                                <div
                                  class="personal-content d-flex align-items-center mb-3"
                                >
                                  <img
                                    src={UserAvator}
                                    alt=""
                                    class="me-2"
                                  />
                                  <div class="author-name">
                                    <h6 class="m-0">{auth?.firstName + ' ' + auth?.lastName ?? 'Matthew Tippetts'}</h6>
                                  </div>
                                </div>
                                <div className='w-100 text-center bg-lightgrey'>
                                  {fileExtension === 'mp4' ?
                                    <video autoPlay={true} muted controls className='w-100'>
                                      <source src={product?.productImages[0]} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                    :
                                    <img
                                      src={selectedDeletedImgUrl != "" ? selectedDeletedImgUrl : product?.productImages[0]}
                                      class="contact-img-border"
                                    />
                                  }
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="contact-border-personal bg-white py-5">
                                <h3 class="text-center mb-4">{t('Personalize')}</h3>
                                <p class="text-center">
                                  {t('drag')}
                                </p>
                                {isDeletePhotoOpen ?
                                  <React.Fragment>
                                    <div className='position-relative'>
                                      <div style={{ position: 'absolute', left: -10, top: -8, width: 25, height: 25, textAlign: 'center', backgroundColor: '#fff', marginRight: 0, borderRadius: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', }} onClick={() => {
                                        setIsDeletePhotoOpen(false)
                                        setSelectedDeletedImgUrl('')
                                        setSelectedDeletedImgUrlForAPI('')
                                      }}>
                                        <i class="fa-solid fa-xmark cursor_pointer" style={{ fontSize: 15, }} aria-label="Close"></i>
                                      </div>
                                      <img
                                        src={selectedDeletedImgUrl}
                                        class="w-100 mb-3"
                                      />
                                    </div>
                                    <div className='row'>
                                      <div className='col-lg-6 col-md-12 mb-4'>
                                        <label
                                          class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                          htmlFor='regularFileInput'
                                        >{fileNames.regular}</label>
                                        <input
                                          {...getInputProps()}
                                          {...getRootProps()}
                                          type="file"
                                          className="custom-file-input"
                                          id="regularFileInput"
                                          accept="image/*"
                                          capture={false}
                                          onChange={(event) => handleFileChange(event, "regular")}
                                        />
                                      </div>
                                      <div className='col-lg-6 col-md-12 mb-4' onClick={() => setShowLiveVideo(true)}>
                                        <label
                                          class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                          htmlFor='liveFileInput'>
                                          {fileNames.live}
                                        </label>
                                      </div>
                                    </div>


                                    <div
                                      onClick={() => handleDeletePhoto()}
                                      class="btn btn-register d-flex justify-content-center align-items-center w-100">
                                      {loading ? <Loader /> :
                                        <React.Fragment>
                                          <p class="lh-sm text-start m-0">{t('confirm')}</p>
                                          <img
                                            src={FlecheIcon}
                                            width="60"
                                          />
                                        </React.Fragment>
                                      }
                                    </div>
                                  </React.Fragment>
                                  :
                                  <React.Fragment>
                                    <DragDropContext onDragEnd={onDragEnd}>
                                      <Droppable droppableId="droppable" direction="horizontal">
                                        {(provided, snapshot) => (
                                          <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                          >
                                            {productImages && productImages.length > 0 && productImages.map((item, index) => {
                                              const fileExtension = item.substring(item.lastIndexOf('.') + 1);
                                              return (
                                                <Draggable key={JSON.stringify(item)} draggableId={JSON.stringify(item)} index={index}>
                                                  {(provided, snapshot) => (
                                                    <div
                                                      className='position-relative  product-close-main'
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                      )}
                                                    >
                                                      <div className='product-close' style={{ position: 'absolute', right: 20, top: 12, width: 25, height: 25, textAlign: 'center', backgroundColor: '#fff', marginRight: 0, borderRadius: 100, justifyContent: 'center', alignItems: 'center', }}
                                                        onClick={() => {
                                                          largePhoto(fileExtension === 'mp4' || fileExtension === 'mov' || fileExtension === 'webm' || fileExtension === 'avi' ? VideoIcon : item)
                                                          setSelectedDeletedImgUrlForAPI(item)
                                                        }}>
                                                        <i class="fa-solid fa-xmark cursor_pointer" style={{ fontSize: 15, }} aria-label="Close"></i>
                                                      </div>

                                                      <img
                                                        src={fileExtension === 'mp4' || fileExtension === 'mov' || fileExtension === 'webm' || fileExtension === 'avi' ? VideoIcon : item}
                                                        class="personal-add-item-img me-md-2 me-4 mb-3"
                                                      />
                                                      {/* } */}
                                                    </div>
                                                  )}
                                                </Draggable>
                                              )
                                            })}
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </DragDropContext>
                                    <div className='row mt-5'>

                                      <div className='col-lg-6 col-md-12 mb-4'>
                                        <label
                                          class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                          htmlFor='regularFileInput'
                                        >{fileNames.regular}</label>
                                        <input
                                          {...getInputProps()}
                                          {...getRootProps()}
                                          type="file"
                                          className="custom-file-input"
                                          id="regularFileInput"
                                          accept="image/*"
                                          capture={false}
                                          onChange={(event) => handleFileChange(event, "regular")}
                                        />
                                      </div>
                                      <div className='col-lg-6 col-md-12 mb-4' onClick={() => setShowLiveVideo(true)}>
                                        <label
                                          class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                          style={{ textAlign: 'center' }}
                                          htmlFor='liveFileInput'>
                                          {fileNames.live}
                                        </label>

                                      </div>
                                    </div>
                                    {LiveVideoIsVisible &&
                                      <React.Fragment>
                                        <Webcam style={{ width: "100%" }} audio={true} ref={webcamRef1} />
                                        {capturing ? (
                                          <div
                                            onClick={handleStopCaptureClick}
                                            style={{ marginTop: 10, marginBottom: 10 }}
                                            class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                            Stop Capture
                                          </div>
                                        ) : (
                                          <div
                                            onClick={handleStartCaptureClick}
                                            style={{ marginTop: 10, marginBottom: 10 }}
                                            class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                            Start Capture
                                          </div>
                                        )}
                                        {recordedChunks.length > 0 && (
                                          <div
                                            onClick={handleUploadVideo}
                                            style={{ marginTop: 10, marginBottom: 10 }}
                                            class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                            Upload Video
                                          </div>
                                        )}
                                      </React.Fragment>
                                    }
                                    {showLiveVideo &&
                                      <React.Fragment>
                                        <Webcam
                                          audio={false}
                                          ref={webcamRef}
                                          screenshotFormat="image/png"
                                          style={{ width: "100%" }}
                                          videoConstraints={videoConstraints}
                                        />
                                        <div
                                          onClick={() => capture()}
                                          style={{ marginTop: 10, marginBottom: 10 }}
                                          class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                          {t('Capture photo')}
                                        </div>
                                        <div
                                          onClick={() => {
                                            setLiveVideoIsVisible(true)
                                            setShowLiveVideo(false)
                                          }}
                                          style={{ marginTop: 10, marginBottom: 10 }}
                                          class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">
                                          Live Video
                                        </div>
                                      </React.Fragment>
                                    }
                                    <button
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModalDesktop4"
                                      class="btn btn-register d-flex justify-content-center align-items-center w-100 mt-5"
                                      disabled={loading}
                                      onClick={() => validateGift()}
                                    >
                                      {loading ? <Loader /> :
                                        <>
                                          <p class="lh-sm text-start m-0">{t('confirm')}</p>
                                          <img
                                            src={FlecheIcon}
                                            width="60"
                                          />
                                        </>
                                      }
                                    </button>
                                  </React.Fragment>
                                }
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              }



              {/* <Link
              to="/login"
              class="text-decoration-none fs-6 fw-bold">Login</Link> */}
              {/* <li class="ms-5 cursor_pointer" >
              <Link to={'/collection'}>
              <h6
                class="m-0 fw-bold cursor_pointer"
              >
                Offrir
              </h6>
              </Link>
            </li> */}

              {window.location.pathname.split('/')[1] === 'collection' &&
                <>
                  <li class="ms-5 cursor_pointer">
                    <a href="https://1-art.co/#concept" className='m-0 fw-bold cursor_pointer text-decoration-none text-dark'>
                      <h6
                        class="m-0 fw-bold cursor_pointer"
                      >
                        {t('Concept')}
                      </h6>
                    </a>
                  </li>
                  <li class="ms-5 cursor_pointer">
                    <a href="https://1-art.co/#collection" className='m-0 fw-bold cursor_pointer text-decoration-none text-dark'>
                      <h6
                        class="m-0 fw-bold cursor_pointer"
                      >
                        {t('Collection')}
                      </h6>
                    </a>
                  </li>
                  <li class="ms-5 cursor_pointer" >
                    <Link to={auth?.id ? "/collection" : "/login"} style={{ textDecoration: "none", color: 'black' }}>
                      <h6
                        class="m-0 fw-bold cursor_pointer"
                      >
                        {t('myCollection')}
                      </h6>
                    </Link>
                  </li>
                </>
              }

              {window.location.pathname.split('/')[1] != 'collection' &&
                <li class="ms-5 cursor_pointer">
                  <h6
                    class="m-0 fw-bold cursor_pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalDesktop1"
                  >
                    {t('Transaction')}
                  </h6>
                  <div
                    class="modal fade"
                    id="exampleModalDesktop1"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-body">
                          <div
                            class="d-flex justify-content-center align-items-center"
                          >
                            <div class="w_544px">
                              <div class="contact-border-personal bg-white py-5">
                                <button
                                  type="button"
                                  className="btn-close"
                                  aria-label="Close"
                                  data-bs-dismiss="modal"
                                  style={{ position: 'absolute', right: 30, top: 30 }}
                                ></button>
                                <h3 class="text-center mb-5">{t('Transaction')}</h3>
                                <div class="position-relative mb-3 mt-5">
                                  <label
                                    for="formGroupExampleInput"
                                    class="form-label bg-white start_15 position-absolute px-3"
                                  >{t('Type')}</label>
                                  <select
                                    class="form-select custom-select w_400 w-100"
                                    id="inputGroupSelect01"
                                    onChange={(e) => {
                                      if (e.target.value === "Lost" || e.target.value === "Stolen") {
                                        setTransactionMessage(e.target.value)
                                        setTransactionFirstName(auth?.firstName)
                                        setTransactionLastName(auth?.lastName)
                                        setTransactionEmail(auth?.email)
                                        setTransactionConfirmEmail(auth?.email)
                                      } else {
                                        setTransactionMessage(e.target.value)
                                        setTransactionFirstName("")
                                        setTransactionLastName("")
                                        setTransactionEmail("")
                                        setTransactionConfirmEmail("")
                                        // setTransactionMessage(e.target.value)
                                      }
                                    }}
                                  >
                                    <option selected></option>
                                    <option value="Lost">{t('Lost')}</option>
                                    <option value="Sale">{t('Sold')}</option>
                                    <option value="Stolen">{t('Stolen')}</option>
                                    <option value="Found">{t('Found')}</option>
                                    <option value="Transfer">{t('Transfer')}</option>
                                  </select>
                                </div>
                                {transactionMessage === 'Lost' || transactionMessage === 'Stolen' || transactionMessage === 'Found' ?
                                  null :
                                  <>
                                    <div class="d-flex justify-content-between mb-3">
                                      <div class="position-relative">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('firstName')}</label>
                                        <input
                                          type="text"
                                          class="form-control w_184_h"
                                          id="formGroupExampleInput"
                                          value={transactionFirstName}
                                          onChange={(e) => setTransactionFirstName(e.target.value)}
                                        />
                                      </div>
                                      <div class="position-relative">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15_contact position-absolute px-3"
                                        >{t('lastName')}</label>
                                        <input
                                          type="text"
                                          class="form-control w_174"
                                          id="formGroupExampleInput"
                                          value={transactionLastName}
                                          onChange={(e) => setTransactionLastName(e.target.value)}
                                        />
                                      </div>
                                    </div>
                                    <div class="position-relative mb-3">
                                      <label
                                        for="formGroupExampleInput"
                                        class="form-label bg-white start_15 position-absolute px-3"
                                      >{t('E-mail')}</label>
                                      <input
                                        type="text"
                                        class="form-control w_400 w-100"
                                        id="formGroupExampleInput"
                                        value={transactionEmail}
                                        onChange={(e) => setTransactionEmail(e.target.value)}
                                      />
                                    </div>
                                    <div class="position-relative mb-3">
                                      <label
                                        for="formGroupExampleInput"
                                        class="form-label bg-white start_15 position-absolute px-3"
                                      >{t('Confirmation')}</label>
                                      <input
                                        type="text"
                                        class="form-control w_400 w-100"
                                        id="formGroupExampleInput"
                                        value={transactionConfirmEmail}
                                        onChange={(e) => setTransactionConfirmEmail(e.target.value)}
                                      />
                                    </div>
                                  </>
                                }
                                <button
                                  data-bs-toggle={transactionFirstName != "" && transactionLastName != "" && transactionEmail != "" && transactionMessage != "" && "modal"}
                                  data-bs-target={transactionFirstName != "" && transactionLastName != "" && transactionEmail != "" && transactionMessage != "" && "#exampleModalDesktop2"}
                                  class="btn btn-register d-flex justify-content-center align-items-center w-100"
                                  disabled={loading}
                                  onClick={() => sendOtp()}
                                >
                                  {loading ? <Loader /> :
                                    <React.Fragment>
                                      <p class="lh-sm text-start m-0">{t('confirm')}</p>
                                      <img
                                        src={FlecheIcon}
                                        width="60"
                                      />
                                    </React.Fragment>
                                  }
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* TRANSACTION */}
                  <div
                    class="modal fade"
                    id="exampleModalDesktop2"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-body">
                          <div
                            class="d-flex justify-content-center align-items-center"
                          >
                            <div class="w_544px">
                              <div class="contact-border-personal bg-white py-5">
                                <button
                                  type="button"
                                  className="btn-close"
                                  aria-label="Close"
                                  data-bs-dismiss="modal"
                                  style={{ position: 'absolute', right: 30, top: 30 }}
                                ></button>
                                <h3 class="text-center mb-5">{t('Transaction')}</h3>
                                <p class="transaction-para">
                                  {t('receivedEmail')}
                                  ({auth?.email ?? 'matthew*********1fab.com'} ) {t('emailConfirmation')}
                                </p>
                                <div
                                  class="d-flex justify-content-between align-items-center"
                                >
                                  <hr class="w_100 fw-bold" />
                                  <p class="transaction-para mx-3 m-0">
                                    {t('securityCode')}
                                  </p>
                                  <hr class="w_100" />
                                </div>
                                <ul
                                  class="d-flex justify-content-between align-items-center list-unstyled mb-5"
                                >
                                  <OtpInput
                                    value={opt1}
                                    onChange={setOtp1}
                                    numInputs={6}
                                    renderSeparator={<span>  -  </span>}
                                    renderInput={(props) => <input {...props} />}
                                    containerStyle={{ marginLeft: '15%', marginTop: 10 }}
                                    inputStyle={{ alignSelf: 'center', width: 40, height: 40, borderRadius: 4 }}
                                  />
                                </ul>
                                <button
                                  data-bs-dismiss="modal"
                                  aria-label='Close'
                                  class="btn btn-register d-flex justify-content-center align-items-center w-100"
                                  onClick={() => verifyTransactionOtp(false)}
                                >
                                  {loading ? <Loader /> :
                                    <React.Fragment>
                                      <p class="lh-sm text-start m-0">{t('confirm')}</p>
                                      <img
                                        src={FlecheIcon}
                                        width="60"
                                      />
                                    </React.Fragment>
                                  }
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              }
              {window.location.pathname.split('/')[1] != 'collection' &&
                <li class="ms-5 cursor_pointer">
                  <h6
                    class="m-0 fw-bold cursor_pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalDesktop3"
                  >
                    {t('Offrir')}
                  </h6>
                  <div
                    class="modal fade"
                    id="exampleModalDesktop3"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-body">
                          <div
                            class="d-flex justify-content-center align-items-center"
                          >
                            <div class="w_544px">
                              <div class="contact-border-personal bg-white py-5">
                                <button
                                  type="button"
                                  className="btn-close"
                                  aria-label="Close"
                                  data-bs-dismiss="modal"
                                  style={{ position: 'absolute', right: 30, top: 30 }}
                                ></button>
                                <h3 class="text-center mb-4">{t('Offrir')}</h3>
                                <div class="d-flex justify-content-between mb-3">
                                  <div class="position-relative">
                                    <label
                                      for="formGroupExampleInput"
                                      class="form-label bg-white start_15 position-absolute px-3"
                                    >{t('firstName')}</label>
                                    <input
                                      type="text"
                                      class="form-control w_184_h"
                                      id="formGroupExampleInput"
                                      value={giftFirstName}
                                      onChange={(e) => setGiftFirstName(e.target.value)}
                                    />
                                  </div>
                                  <div class="position-relative">
                                    <label
                                      for="formGroupExampleInput"
                                      class="form-label bg-white start_15_contact position-absolute px-3"
                                    >{t('lastName')}</label>
                                    <input
                                      type="text"
                                      class="form-control w_174"
                                      id="formGroupExampleInput"
                                      value={giftLastName}
                                      onChange={(e) => setGiftLastName(e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div class="position-relative mb-3">
                                  <labe
                                    for="formGroupExampleInput"
                                    class="form-label bg-white start_15 position-absolute px-3"
                                  >{t('E-mail')}</labe>
                                  <input
                                    type="text"
                                    class="form-control w_400 w-100"
                                    id="formGroupExampleInput"
                                    value={giftEmail}
                                    onChange={(e) => setGiftEmail(e.target.value)}
                                  />
                                </div>
                                <div class="position-relative mb-3">
                                  <label
                                    for="formGroupExampleInput"
                                    class="form-label bg-white start_15 position-absolute px-3"
                                  >{t('Confirmation')}</label>
                                  <input
                                    type="text"
                                    class="form-control w_400 w-100"
                                    id="formGroupExampleInput"
                                    value={giftConfirmEmail}
                                    onChange={(e) => setGiftConfirmEmail(e.target.value)}
                                  />
                                </div>
                                <div class="position-relative mb-2">
                                  <label
                                    for="formGroupExampleInput"
                                    class="form-label bg-white start_15 position-absolute px-3"
                                  >{t('Message')}*</label>
                                  <textarea
                                    type="text"
                                    rows="3"
                                    class="form-control w_400_area w-100"
                                    id="formGroupExampleInput"
                                    value={giftMessage}
                                    onChange={(e) => setGiftMessage(e.target.value)}
                                  ></textarea>
                                </div>
                                <div class="differ-border d-flex justify-content-between align-items-center mb-2 w-100">
                                  {giftDiff ? <input type="datetime-local" min={new Date().toISOString().slice(0, 16)} style={{ border: 0, width: "100%", marginRight: 8 }} onChange={onChangeDateTime} /> :
                                    <h5 class="m-0">{t('Schedule Timing')}</h5>
                                  }
                                  <div class="w-auto m-0">
                                    <label class="switch me-3" for="checkbox">
                                      <input type="checkbox" id="checkbox" onClick={() => toggleCheckbox()} />
                                      <div class="slider-differ round"></div>
                                    </label>
                                  </div>
                                </div>



                                <div
                                  class="d-flex justify-content-between align-items-center mb-4"
                                >
                                  <div class="custom-file" data-bs-toggle="modal" data-bs-target="#imageShower">
                                    <label
                                      class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                      htmlFor="regularFileInput1"
                                    >{fileNames.regular1}</label>
                                    <input
                                      {...getInputProps()}
                                      {...getRootProps()}
                                      type="file"
                                      class="custom-file-input"
                                      id="regularFileInput1"
                                      accept="image/*"
                                      capture={false}
                                      onChange={(event) => handleFileChange(event, "regular1")}
                                    />
                                  </div>
                                  <div class="custom-file_2" onClick={() => setLiveVideoIsVisible(true)}>
                                    <label
                                      class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                      htmlFor="liveFileInput1"
                                    >{fileNames.live1}</label>
                                  </div>
                                </div>
                                {LiveVideoIsVisible &&
                                  <React.Fragment>
                                    <Webcam style={{ width: "100%" }} audio={true} ref={webcamRef1} />
                                    {capturing ? (
                                      <div
                                        onClick={handleStopCaptureClick}
                                        style={{ marginTop: 10, marginBottom: 10 }}
                                        class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                        Stop Capture
                                      </div>
                                    ) : (
                                      <div
                                        onClick={handleStartCaptureClick}
                                        style={{ marginTop: 10, marginBottom: 10 }}
                                        class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                        Start Capture
                                      </div>
                                    )}
                                    {recordedChunks.length > 0 && (
                                      <div
                                        onClick={handleUploadVideo}
                                        style={{ marginTop: 10, marginBottom: 10 }}
                                        class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                        Upload Video
                                      </div>
                                    )}
                                  </React.Fragment>
                                }
                                <div class="position-relative"></div>
                                <button
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModalDesktop4"
                                  class="btn btn-register d-flex justify-content-center align-items-center w-100"
                                  disabled={loading}
                                  onClick={() => validateGift()}
                                >
                                  {loading ? <Loader /> :
                                    <>
                                      <p class="lh-sm text-start m-0">{t('confirm')}</p>
                                      <img
                                        src={FlecheIcon}
                                        width="60"
                                      />
                                    </>
                                  }
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="modal fade"
                    id="exampleModalDesktop4"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-body">
                          <div
                            class="d-flex justify-content-center align-items-center"
                          >
                            <div class="w_544px">
                              <div class="contact-border-personal bg-white py-5">
                                <button
                                  type="button"
                                  className="btn-close"
                                  aria-label="Close"
                                  data-bs-dismiss="modal"
                                  style={{ position: 'absolute', right: 30, top: 30 }}
                                ></button>
                                <h3 class="text-center mb-5">{t('Offrir')}</h3>
                                <p class="transaction-para">
                                  {t('receivedEmail')}
                                  ({auth?.email ?? 'matthew*********1fab.com'} ) {t('emailConfirmation')}
                                </p>
                                <div
                                  class="d-flex justify-content-between align-items-center"
                                >
                                  <hr class="w_100 fw-bold" />
                                  <p class="transaction-para mx-3 m-0">
                                    {t('securityCode')}
                                  </p>
                                  <hr class="w_100" />
                                </div>
                                <ul
                                  class="d-flex justify-content-between align-items-center list-unstyled mb-5"
                                >
                                  <OtpInput
                                    value={opt1}
                                    onChange={setOtp1}
                                    numInputs={6}
                                    renderSeparator={<span>  -  </span>}
                                    renderInput={(props) => <input {...props} />}
                                    containerStyle={{ marginLeft: '15%', marginTop: 10 }}
                                    inputStyle={{ alignSelf: 'center', width: 40, height: 40, borderRadius: 4 }}
                                  />
                                </ul>
                                <button
                                  class="btn btn-register d-flex justify-content-center align-items-center w-100"
                                  data-bs-dismiss="modal"
                                  aria-label='Close'
                                  onClick={() => { verifyTransactionOtp(true) }}
                                >
                                  {loading ? <Loader /> :
                                    <>
                                      <p class="lh-sm text-start m-0">{t('confirm')}</p>
                                      <img
                                        src={FlecheIcon}
                                        width="60"
                                      />
                                    </>
                                  }
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              }
            </ul>
          </div> : null
        }
        <div class="menu d-flex justify-content-end align-items-center">
          {auth?.id &&
            <React.Fragment>
              {/* <img
            src={LoupeIcon}
            class="w_icons_navbar mx-3"
          /> */}
              <a href="https://1-art.co/cart">
                <img
                  src={BuyIcon}
                  class="w_icons_navbar mx-3"
                />
              </a>
              {/* <Link to={auth?.id ? '#' : "/sign_up"}>
            <img
              src={UtilisateurIcon}
              class="w_icons_navbar mx-3"
            />
          </Link> */}
              <Custom_dropdown isProductOwner={isProductOwner} />
              <div class="dropdown">
                <button
                  class="btn btn- dropdown-toggle bg-tranparent"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* <span>
                    <img
                      src={FranceIcon}
                      class="w_icons_navbar_flag"
                      alt="flag"
                    />
                  </span>{" "}
                  {"/"} {"€"}  */}
                  {/* {auth?.firstName ? auth?.firstName : ""} */}
                  {/* <span className='fs-4'>{"/"}</span>  */}
                  {/* {"€"}  */}
                  {cookies.get("i18next") === 'fr' ? 'French' : 'English'}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                  <li>
                    <button class="dropdown-item" type="button" onClick={() => changeLang("en")}>
                      English
                    </button>
                  </li>
                  <li>
                    <button class="dropdown-item" type="button" onClick={() => changeLang("fr")}>
                      French
                    </button>
                  </li>
                </ul>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                  {auth?.id &&
                    <li>
                      <button class="dropdown-item" type="button" onClick={() => handleLogout()}>
                        {t('logOut')}
                      </button>
                    </li>
                  }
                </ul>
              </div>
            </React.Fragment>
          }
        </div>
      </div>
    </header>
  )
}

export default Header